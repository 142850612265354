<template>
    <NavBar></NavBar>
    <div class="payslip">
        <div class="surface-card p-2 shadow-2">
            <form @submit.prevent="handleSubmit">
                <div class="grid">
                    <div class="col-6">
                        <Calendar placeholder="Month" class="w-full" v-model="paySlipform.month" view="month"
                            dateFormat="MM" :class="[v$.paySlipform.month.$error ? 'p-invalid' : '']" />
                        <small class="p-error" v-if="v$.paySlipform.month.$error">Month is Required</small>
                    </div>
                    <div class="col-6">
                        <Calendar placeholder="Year" class="w-full" v-model="paySlipform.year" view="year"
                            dateFormat="yy" :class="[v$.paySlipform.year.$error ? 'p-invalid' : '']" />
                        <small class="p-error" v-if="v$.paySlipform.year.$error">Year is Required</small>

                    </div>
                    <div class="col-12 text-center">
                        <Button type="submit" label="Show" :icon="psLoading ? 'pi pi-spin pi-spinner' : ' pi pi-search'"
                            outlined>
                        </Button>
                    </div>
                </div>
            </form>
        </div>
        <div v-if="paySlipData" class="mt-2">

            <div class="surface-card p-2 shadow-2">
                <span class="mb-2"><strong>PAY SLIP</strong></span><br />
                <span><strong>YU GUO CHINESE PHYSICAN PTE LTD</strong></span>
                <table class="table mt-2 w-full">
                    <tr>
                        <td>
                            <div class="flex align-items-center">
                                <label class="mr-2"> Name </label>
                                <label class="underline">{{ paySlipData.EmployeeName }}</label>
                            </div>
                        </td>
                        <td>
                            <div class="flex align-items-center">
                                <label class="mr-2"> Pay Period </label>
                                <label class="underline">{{ paySlipData.PayPeriod }}</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        </td>
                        <td>
                            <div class="flex align-items-center">
                                <label class="mr-2"> Date </label>
                                <label class="underline">{{ paySlipData.Date }}</label>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>

            <div class="datatable overflow-x-auto surface-card p-2 shadow-2">
                <table class="table table-border w-full mt-2">
                    <tr>
                        <th>Basic Pay</th>
                        <th v-show="!isFullTimeEmployee">Rate</th>
                        <th v-show="!isFullTimeEmployee">Shift</th>
                        <th>Bonus</th>
                        <th v-show="isFullTimeEmployee">U/P Leave</th>
                        <th v-show="isFullTimeEmployee">ALW</th>
                        <th>Jump</th>
                        <th>Sun</th>
                        <th v-show="isFullTimeEmployee">AL取</th>
                        <th v-show="isFullTimeEmployee">AL剩</th>
                        <th v-show="!isFullTimeEmployee">Al</th>
                        <th>MC</th>
                        <th v-show="!isFullTimeEmployee">PH</th>
                        <th>Dates</th>
                    </tr>
                    <tr>
                        <td>{{ paySlipData.BasicPay }}</td>
                        <td v-show="!isFullTimeEmployee">${{ paySlipData.Rate }}</td>
                        <td v-show="!isFullTimeEmployee">{{ paySlipData.Shift }}</td>
                        <td>{{ paySlipData.Bonus }}</td>
                        <td v-show="isFullTimeEmployee">{{ paySlipData.U_P_Leave }}</td>
                        <td v-show="isFullTimeEmployee">{{ paySlipData.Allowance }}</td>
                        <td>{{ paySlipData.Jump }}</td>
                        <td>{{ paySlipData.Sun }}</td>
                        <td v-show="isFullTimeEmployee">{{ paySlipData.LeaveTaken }}</td>
                        <td v-show="isFullTimeEmployee">{{ paySlipData.RemainingLeave }}</td>
                        <td v-show="!isFullTimeEmployee">{{ paySlipData.Al }}</td>
                        <td>{{ paySlipData.MC }}</td>
                        <td v-show="!isFullTimeEmployee">{{ paySlipData.PH }}</td>
                        <td class="border-bottom-none">{{ paySlipData.Dates }}</td>
                    </tr>
                    <tr>
                        <th>Gross Pay</th>
                        <th colspan="2">Employee CPF</th>
                        <th colspan="2">Empoyer CPF</th>
                        <th>Total CPF</th>
                        <th colspan="3">NET Pay</th>
                        <th class="border-top-none border-bottom-none"></th>
                    </tr>
                    <tr>
                        <td>$ {{ paySlipData.GrossP }}</td>
                        <td colspan="2">{{ paySlipData.EeCpf }}</td>
                        <td colspan="2">{{ paySlipData.ErCpf }}</td>
                        <td>{{ paySlipData.TotalCpf }}</td>
                        <td colspan="3">{{ paySlipData.NetPay }}</td>
                        <td class="border-top-none"></td>
                    </tr>
                </table>
            </div>
            <div class="card flex flex-wrap gap-2 justify-content-center mt-3">
                <Button @click="handleDownload" label="Save to PDF"
                    :icon="downloadLoading ? 'pi pi-spin pi-spinner' : 'pi pi-file-pdf'" outlined />
                <Button @click="handleEmail" label="Send to Email"
                    :icon="emailLoading ? 'pi pi-spin pi-spinner' : 'pi pi-file-pdf'" outlined />
            </div>
        </div>

    </div>
    <Footer></Footer>
</template>



<script>
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import axios from 'axios';

export default {
    name: 'PaySlip',
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            paySlipform: {
                month: new Date(),
                year: new Date(),
            },
            paySlipData: null,
            psLoading: false,
            downloadLoading: false,
            emailLoading: false,

            downloadOption: {
                responseType: 'blob'
            }

        }
    },
    validations() {
        return {
            paySlipform: {
                month: { required },
                year: { required }
            }
        }
    },
    components: {
        NavBar,
        Footer
    },
    computed: {
        isFullTimeEmployee() {
            return this.paySlipData && this.paySlipData.EmployeeType == '1';
        }
    },
    methods: {
        async handleSubmit() {
            this.psLoading = true;
            const result = await this.v$.$validate();
            if (result) {
                let month = new Date(this.paySlipform.month).getMonth() + 1;
                let year = new Date(this.paySlipform.year).getFullYear();
                try {
                    const response = await axios.get('payslips?request.year=' + year + '&request.month=' + month);
                    if (response.status == 200) {
                        this.psLoading = false;
                        if (response.data.success) {
                            this.$toast.add({ severity: 'success', detail: response.data.message, group: 'br', life: 5000 });

                            this.paySlipData = response.data.result;
                        } else {
                            this.$toast.add({ severity: 'error', detail: response.data.message, group: 'br', life: 5000 });
                        }
                    } else {
                        this.psLoading = false;
                    }
                } catch (error) {
                    this.psLoading = false;
                    console.log(error)
                }

            } else {
                this.psLoading = false;
            }
        },
        async handleDownload() {
            this.downloadLoading = true;
            let month = new Date(this.paySlipform.month).getMonth() + 1;
            let year = new Date(this.paySlipform.year).getFullYear();
            try {
                const response = await axios.get('payslips/download?request.year=' + year + '&request.month=' + month, this.downloadOption);
                console.log(response)
                if (response.status == 200) {
                    this.downloadLoading = false;

                    const blob = response.data;
                    const url = window.URL.createObjectURL(blob);
                    console.log(url);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = "samplefile.pdf";
                    document.body.appendChild(a);
                    a.click();
                    a.remove();

                } else {
                    this.downloadLoading = false;
                    this.$toast.add({ severity: 'error', detail: response.data.message, group: 'br', life: 5000 });
                }
                console.log(response);

            } catch (error) {
                this.downloadLoading = false;
                console.log(error)
            }
        },
        async handleEmail() {
            this.emailLoading = true;
            let month = new Date(this.paySlipform.month).getMonth() + 1;
            let year = new Date(this.paySlipform.year).getFullYear();
            try {
                const response = await axios.get('payslips/email?request.year=' + year + '&request.month=' + month);
                if (response.status == 200) {
                    this.emailLoading = false;
                    if (response.data.success) {
                        this.$toast.add({ severity: 'success', detail: response.data.message, group: 'br', life: 5000 });
                    } else {
                        this.$toast.add({ severity: 'error', detail: response.data.message, group: 'br', life: 5000 });
                    }
                } else {
                    this.emailLoading = false;
                    this.$toast.add({ severity: 'error', detail: response.data.message, group: 'br', life: 5000 });
                }
                console.log(response);

            } catch (error) {
                this.emailLoading = false;
                console.log(error)
            }
        }
    }

}
</script>
