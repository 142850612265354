<template>
  <NavBar></NavBar>
  <div class="roster">
    <div class="surface-card p-2 shadow-2">
      <form @submit.prevent="handleSubmit">
        <div class="grid">
          <div class="col-6">
            <Calendar placeholder="Month" class="w-full" v-model="rosterForm.month" view="month" dateFormat="MM"
              :class="[v$.rosterForm.month.$error ? 'p-invalid' : '']" />
            <small class="p-error" v-if="v$.rosterForm.month.$error">Month is Required</small>
          </div>
          <div class="col-6">
            <Calendar placeholder="Year" class="w-full" v-model="rosterForm.year" view="year" dateFormat="yy"
              :class="[v$.rosterForm.year.$error ? 'p-invalid' : '']" />
            <small class="p-error" v-if="v$.rosterForm.year.$error">Year is Required</small>
          </div>
          <div class="col-12 text-center">
            <Button type="submit" label="Show" :icon="Loading ? 'pi pi-spin pi-spinner' : ' pi pi-search'" outlined>
            </Button>
          </div>
        </div>
      </form>
    </div>
    <div class="mt-2" v-if="rosterData">
      <ScrollPanel style="width: 100%; height: 185px" :pt="{
        wrapper: {
          style:
            'border-right: 5px solid var(--surface-50); border-bottom: 5px solid var(--surface-50);',
        },
        bary: 'surface-300 opacity-100 border-noround',
      }">
        <table class="w-full table table-border">
          <tr>
            <th>No</th>
            <th>Date</th>
            <th>Shift Name</th>
            <th>Status</th>
            <th>In</th>
            <th>Out</th>
            <th v-if="canEdit">Action</th>
          </tr>
          <tr v-for="(item, index) in rosterData.Rosters" :key="item.ShiftSetupId">
            <td>{{ index + 1 }}</td>
            <td>{{ $filters.formateDate(item.ShiftDate) }}</td>
            <td>{{ item.Shift }}</td>
            <td :style="'color:' + getApprovedStatus(item).color" class="font-bold text-xs">
              {{ getApprovedStatus(item).text }}
            </td>
            <td class="text-sm">{{ item.FormattedInTime }}</td>
            <td class="text-sm">{{ item.FormattedOutTime }}</td>
            <td v-if="!item.IsApproved && canEdit">
              <Button icon="pi pi-pencil" severity="success" text aria-label="edit" size="small"
                @click="showModal(item)" />
            </td>
          </tr>
        </table>
      </ScrollPanel>

      <div class="mt-2">
        <h4 class="text-center">{{ getShiftWorkedMessage }}</h4>
        <div class="card flex flex-wrap gap-4 justify-content-center"
          v-if="(hasNoAttendanceRecord || !isAllApproved) && !canEdit">
          <Button label="Yes" icon="pi pi-check" size="small" outlined severity="success" @click="canEdit = true" />
          <Button label="No" icon="pi pi-times" size="small" outlined severity="danger" @click="reloadWindow" />
        </div>
        <div class="card flex flex-wrap gap-4 justify-content-center mt-2" v-if="canEdit">
          <Button label="Confirm" icon="pi pi-check" size="small" outlined severity="success" @click="updateShift" />
        </div>
      </div>
    </div>
    <Footer></Footer>

    <Dialog v-model:visible="modal" modal header="Update Shift" :style="{ width: '50vw' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <div class="shiftUpdate">
        <div class="grid mb-3">
          <div class="col-6">
            <label class="mb-2">Date</label>
            <InputText v-model="shiftForm.date" disabled placeholder="Disabled" class="w-full" />
          </div>
          <div class="col-6">
            <label class="mb-2">Shift</label>
            <!-- <InputText v-model="shiftForm.shift" disabled placeholder="Disabled" class="w-full" /> -->
            <Dropdown v-model="shiftForm.selectedShiftId" :options="shiftData.shifts" optionLabel="name"
              optionValue="value" placeholder="Select a shift" class="w-full" />
          </div>
        </div>
        <div class="grid">
          <div class="col-4 flex align-items-center">
            <label>Time In</label>
          </div>
          <div class="col-8">
            <!-- <Calendar id="calendar-timeonly" v-model="shiftForm.In" timeOnly hourFormat="24" class="w-full" /> -->
            <input type="time" v-model="shiftForm.In" class="w-full p-1" />
          </div>
        </div>
        <div class="grid">
          <div class="col-4 flex align-items-center">
            <label>Time Out</label>
          </div>
          <div class="col-8">
            <!-- <Calendar id="calendar-timeonly" v-model="shiftForm.out" timeOnly hourFormat="24" class="w-full" /> -->
            <input type="time" v-model="shiftForm.out" class="w-full p-1" />
          </div>
        </div>
      </div>
      <template #footer>
        <Button @click="() => deleteShift(shiftForm.attendanceId)" label="Delete" severity="danger"
          v-show="shiftForm.attendanceId" icon="pi pi-times" outlined />
        <Button @click="handleUpdate" label="Update" severity="success" icon="pi pi-check" outlined />
      </template>
    </Dialog>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";
export default {
  name: "RosterView",
  setup: () => ({ v$: useVuelidate() }),
  components: {
    NavBar,
    Footer,
  },
  created() {
    this.getShiftSetup();
  },
  data() {
    return {
      isBusy: false,
      rosterForm: {
        month: new Date(),
        year: new Date(),
      },
      shiftForm: {
        date: new Date(),
        shift: null,
        In: null,
        out: null,
        id: null,
        selectedShiftId: null,
        attendanceId: null,
      },
      Loading: false,
      canEdit: false,
      rosterData: null,
      currentModal: null,
      modal: false,
      modalToUpdate: [],
      shiftData: {
        shiftId: null,
        shifts: [],
      },
    };
  },
  computed: {
    noOfShiftWorked() {
      return this.rosterData.Rosters.filter((x) => x.InTime && x.OutTime)
        .length;
    },
    canShowYesNoButton() {
      return this.rosterData.Rosters.filter((x) => !x.IsApproved).length > 0;
    },
    hasPendingData() {
      //has previously submitted data
      return this.pendingCount > 0;
    },
    pendingCount() {
      return this.rosterData.Rosters.filter((x) => !x.IsApproved && x.IsLocked)
        .length;
    },
    isAllApproved() {
      return (
        this.rosterData.Rosters.filter(
          (x) => !x.IsApproved && x.InTime && x.OutTime
        ).length == 0
      );
    },
    hasNoAttendanceRecord() {
      return (
        this.rosterData.Rosters.filter(
          (x) => x.InTime == null && x.OutTime == null
        ).length == this.rosterData.Rosters.length
      );
    },
    getShiftWorkedMessage() {
      const message =
        "You have " +
        this.rosterData.ShiftWorkedCount +
        " Worked for this month. Do you want edit the Data?";
      if (this.hasNoAttendanceRecord) {
        return message;
      }
      if (this.isAllApproved) {
        return (
          "You have " +
          this.rosterData.ShiftWorkedCount +
          " Worked for this month. Approved by Admin."
        );
      } else if (this.hasPendingData) {
        return `You have already submitted the data (${this.pendingCount} shifts for this month). The status is pending, Do you want to edit the Data?`;
      }
      return message;
    },
  },
  validations() {
    return {
      rosterForm: {
        month: { required },
        year: { required },
      },
    };
  },
  methods: {
    reloadWindow() {
      window.location.reload();
    },
    getApprovedStatus(item) {
      if (item.IsApproved == true) {
        return {
          text: "Approved",
          color: "green",
        };
      } else if (item.IsApproved == false) {
        return {
          text: "Rejected",
          color: "red",
        };
      } else {
        if (!item.AttendanceId) {
          return {
            text: "Unknown",
            color: "gray",
          };
        }
        return {
          text: "Pending",
          color: "orange",
        };
      }
    },
    async handleSubmit() {
      console.log("submit");
      this.Loading = true;
      this.canEdit = false;
      const result = await this.v$.$validate();
      if (result) {
        let month = new Date(this.rosterForm.month).getMonth() + 1;
        let year = new Date(this.rosterForm.year).getFullYear();
        try {
          const response = await axios.get(
            "rosters?request.year=" + year + "&request.month=" + month
          );
          console.log(response);
          if (response.status == 200) {
            this.Loading = false;
            if (response.data.success) {
              this.$toast.add({
                severity: "success",
                detail: response.data.message,
                group: "br",
                life: 5000,
              });

              this.rosterData = response.data.result;
            } else {
              this.$toast.add({
                severity: "error",
                detail: response.data.message,
                group: "br",
                life: 5000,
              });
            }
          } else {
            this.Loading = false;
          }
        } catch (error) {
          this.Loading = false;
        }
      } else {
        this.Loading = false;
      }
    },
    //cell in/out time update (in memory)
    handleUpdate() {
      if (this.shiftForm.In == null || this.shiftForm.out == null) {
        this.$toast.add({
          severity: "error",
          detail: "Shift Time Can not be Empty",
          group: "br",
          life: 5000,
        });
        return;
      }
      if (this.shiftForm.selectedShiftId == null) {
        this.$toast.add({
          severity: "error",
          detail: "Please select a valid shift",
          group: "br",
          life: 5000,
        });
        return;
      }
      let theRoster = this.rosterData.Rosters.find(
        (x) => x.UniqueId === this.shiftForm.id
      );
      theRoster.isEdited = true;
      theRoster.FormattedInTime = this.shiftForm.In;
      theRoster.FormattedOutTime = this.shiftForm.out;
      theRoster.ShiftSetupId = this.shiftForm.selectedShiftId;
      theRoster.Shift = this.shiftData.shifts.find(
        (x) => x.value === this.shiftForm.selectedShiftId
      )?.name;
      theRoster.InTime = this.shiftForm.In;
      theRoster.OutTime = this.shiftForm.out;
      this.$toast.add({
        severity: "success",
        detail: "Shift updated Successfully",
        group: "br",
        life: 5000,
      });
      this.modal = false;
    },
    showModal(obj) {
      this.currentModal = obj;
      this.shiftForm.id = obj.UniqueId;
      this.shiftForm.date = obj.FormattedShiftDate;
      this.shiftForm.shift = obj.Shift;
      this.shiftForm.In = obj.FormattedInTime;
      this.shiftForm.out = obj.FormattedOutTime;
      this.shiftForm.selectedShiftId = obj.ShiftSetupId;
      this.shiftForm.attendanceId = obj.AttendanceId;
      this.modal = true;
    },
    async getShiftSetup() {
      try {
        const response = await axios.get("shiftsetup");
        console.log(response);
        if (response.status == 200) {
          if (response.data.success) {
            this.shiftData.shifts = response.data.result;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async deleteShift(attendnaceId) {
      try {
        this.modal = false;
        if (!confirm("Are you sure to delete this shift?")) return;
        const response = await axios.delete("delete-attendance", {
          params: {
            attendnaceId,
          },
        });
        if (response.data.success) {
          this.$toast.add({
            severity: "success",
            detail: response.data.message,
            group: "br",
            life: 5000,
          });
          window.location.reload();
        } else {
          this.$toast.add({
            severity: "error",
            detail: response.data.message,
            group: "br",
            life: 5000,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async updateShift() {
      if (this.isBusy) return;
      let data = {
        Month: new Date(this.rosterForm.month).getMonth() + 1,
        Year: new Date(this.rosterForm.month).getFullYear(),
        Rosters: this.rosterData.Rosters.filter((x) => x.InTime && x.OutTime),
      };
      try {
        this.isBusy = false;
        const response = await axios.post("rosters", data);
        console.log(response);
        if (response.status == 200) {
          if (response.data.success) {
            this.$toast.add({
              severity: "success",
              detail: response.data.message,
              group: "br",
              life: 5000,
            });
            window.location.reload();
          } else {
            this.$toast.add({
              severity: "error",
              detail: response.data.message,
              group: "br",
              life: 5000,
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
      finally {
        this.isBusy = false;
      }
    },
  },
};
</script>
