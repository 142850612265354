<template>
    <NavBar></NavBar>
    <div class="qrcode flex align-items-center justify-content-center">
        <div class="qrImage" v-if="value && IsAdmin">
            <qrcode-vue :value="value" :size="size" level="H" />
        </div>
    </div>
    <!-- <Footer></Footer> -->
</template>



<script>
import NavBar from '@/components/NavBar.vue';
//import Footer from '@/components/Footer.vue';

import axios from 'axios';
import QrcodeVue from 'qrcode.vue';



export default {
    name: 'AttendanceView',
    components: {
        NavBar,
        // Footer,
        QrcodeVue,
    },
    data() {
        return {
            value: '',
            size: 300,
            qrloader: false,
            refreshTimer: null,
        }
    },
    created() {
        this.genrateQR();
    },
    methods: {
        refreshQR(intervalMs) {
            if (this.refreshTimer) {
                window.clearInterval(this.refreshTimer);
            }
            this.refreshTimer = window.setInterval(() => {
                this.genrateQR()
            }, intervalMs);
        },
        async genrateQR() {
            try {
                const response = await axios.get('qrcode');
                console.log(response)
                if (response.status == 200) {
                    if (response.data.success) {
                        this.value = response.data.result.qr;
                        this.$toast.add({ severity: 'success', detail: response.data.message, group: 'br', life: 5000 });
                        this.refreshQR(response.data.result.refreshIntervalMs);
                        console.log(response.data.result);
                    } else {
                        this.$toast.add({ severity: 'error', detail: response.data.message, group: 'br', life: 5000 });
                    }
                } else {
                    this.Loading = false;
                }
            } catch (error) {
                console.log(error)
            }
        },
    }

}
</script>
