<template>
  <NavBar></NavBar>
  <div class="qrcode flex align-items-center justify-content-center">
    <div class="qrReader text-center" v-if="reader">
      <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
    </div>
    <Button v-if="again" label="Scan Again" icon="pi pi-check" size="large" outlined severity="success"
      @click="againQR" />

    <!-- <div class="grid w-full" v-if="successMessage">
            <div class="col-10 m-auto">
                <div class="surface-card p-2 shadow-2">
                    <h2 class="text-green-600 text-center">"Attendance update successful!"</h2>
                </div>
            </div>
        </div> -->
    <div class="mt-2 mb-2" v-if="showInOut">
      <div class="card flex flex-wrap gap-2 justify-content-center">
        <Button label="In" severity="success" size="large" v-if="attendanceStatus == 'CheckIn'" outlined
          @click="submitQr(1)" />
        <Button label="Out" severity="danger" size="large" v-else outlined @click="submitQr(2)" />
      </div>
    </div>
  </div>

  <Footer></Footer>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";

import axios from "axios";
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  name: "RecordAttendanceView",
  components: {
    NavBar,
    StreamBarcodeReader,
    Footer,
  },
  data() {
    return {
      isBusy: false,
      reader: true,
      successMessage: false,
      inOut: null,
      QrData: null,
      again: false,
      showInOut: false,
      attendanceStatus: "CheckIn",
    };
  },
  created() {
    this.getAttendanceStatus();
  },
  methods: {
    onDecode(result) {
      if (result) {
        this.reader = false;
        this.showInOut = true;
        this.QrData = result;
      }
    },
    async getAttendanceStatus() {
      try {
        const response = await axios.get("attendance-status");
        if (response.status == 200) {
          if (response.data.success) {
            this.attendanceStatus = response.data.result.Status;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async submitQr(value) {
      if (this.isBusy) return;
      if (value && this.QrData) {
        try {
          this.isBusy = true;
          const response = await axios.put(
            "attendances?qr=" + this.QrData + "&mode=" + value
          );
          if (response.status == 200) {
            if (response.data.success) {
              this.successMessage = true;
              this.$toast.add({
                severity: "success",
                detail: response.data.message,
                group: "br",
                life: 5000,
              });
              this.$router.push("/");
            } else {
              this.again = true;
              this.showInOut = false;
              this.$toast.add({
                severity: "error",
                detail: response.data.message,
                group: "br",
                life: 5000,
              });
            }
          }
        } catch (error) {
          console.log(error);
        }
        finally {
          this.isBusy = false;
        }
      } else {
        this.again = true;
      }
    },
    againQR() {
      this.reader = true;
      this.again = false;
    },
  },
};
</script>
